const { body } = document;
const WIDTH = 992; // refer to Bootstrap's responsive design

export default {
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    device() {
      this.isMobile = !this.$_isMobile();
      return this.isMobile;
    },
  },
  beforeMount() {
    window.addEventListener("resize", this.$_resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.$_resizeHandler);
  },
  methods: {
    $_isMobile() {
      const rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },
    $_resizeHandler() {
      if (!document.hidden) {
        this.isMobile = this.$_isMobile();
      }
    },
  },
};
