import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/locales/en.json'
import ch from '@/locales/ch.json'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: en,
    ch: ch
  },
  fallbackLocale: 'en'
})
