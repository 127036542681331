import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './styles/animate.scss'
import 'vueperslides/dist/vueperslides.css'
import './styles/index.scss'
// Or, if you have to support IE9
import size from './service/sizeHandle'
import Flicking from '@egjs/vue-flicking'
import VueSmoothScroll from 'vue2-smooth-scroll'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'animate.css'
import VueKinesis from 'vue-kinesis'
import { ModalPlugin } from 'bootstrap-vue'
import { i18n } from './i18n'

Vue.use(ModalPlugin)
Vue.use(VueKinesis)
Vue.use(VueSmoothScroll, {
  duration: 800,
  updateHistory: false,
  offset: -59
})
Vue.use(Flicking)

Vue.mixin(size)
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')
